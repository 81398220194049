/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useIntl } from "react-intl";
import { useAuth } from "../core/Auth";
import { registerAgent } from "../core/_requests";
import {
  removeAllSpaces,
  trimSpacesString,
} from "../../../../_metronic/helpers";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const initialValues = {
  telegram: "",
  email: "",
  msg: "",
  acceptTerms: false,
};

const HOST = process.env.REACT_APP_FREEWIN_SITE_HOST;

export function RegistrationAgent() {
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState<any>();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { saveAuth } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();

  const registrationSchema = Yup.object().shape({
    telegram: Yup.string()
      .matches(/^@/, "Telegram должен начинаться с @")
      .min(3, "Минимальная длина Telegram - 3 символа")
      .max(32, "Максимальная длина Telegram - 32 символа")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_EMAIL" }))
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    msg: Yup.string()
      .min(
        5,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 5 })
      )
      .max(4096, "Максимальная длина сообщения - 4096 символов")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.TERMS_REQUIRED" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await registerAgent(
          values.email,
          values.telegram,
          trimSpacesString(values.msg)
          // token
        );
        // setRefreshReCaptcha((r) => !r);

        navigate("/", {
          state: { action: { type: "SUCCESS_REGISTERED" } },
        });
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        let statusText = intl.formatMessage({
          id: "AUTH.VALIDATION.WRONG_CREDITINALS",
        });

        if (error) {
          statusText =
            error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail;
        }

        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        // setRefreshReCaptcha((r) => !r);
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.email) {
      formik.values.email = removeAllSpaces(formik?.values?.email);
    }
  }, [formik?.values?.email]);

  useEffect(() => {
    if (formik?.values?.telegram) {
      formik.values.telegram = removeAllSpaces(formik?.values?.telegram);
    }
  }, [formik?.values?.telegram]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    /* do something like submit a form and then refresh recaptcha */
  }, []);

  return (
    // <GoogleReCaptchaProvider
    //   reCaptchaKey={
    //     process.env.REACT_APP_GOOGLE_PUBLIC_KEY ??
    //     "6Lfmh30nAAAAAHZaOqxgTAEYSAPBHVHNYhDC2DBm"
    //   }
    // >
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-13 text-center">
        <h1 className="mb-4">Партнерская программа</h1>

        <div className="text-gray-500 fw-semibold fs-4">
          Стать партнером
          <a
            href={HOST}
            target="_blank"
            className="ms-2 text-freewin text-uppercase fw-bold fst-italic fs-4"
          >
            freewin
          </a>
          .
        </div>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        /> */}

      <div className="fv-row mb-10">
        <label className="form-label fw-bolder text-dark fs-6">
          Email пользователя FREEWIN
        </label>
        <input
          placeholder="user@domain.com"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fw-bolder text-dark fs-6">Telegram</label>
        <input
          placeholder="@username"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("telegram")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.telegram && formik.errors.telegram,
            },
            {
              "is-valid": formik.touched.telegram && !formik.errors.telegram,
            }
          )}
        />
        {formik.touched.telegram && formik.errors.telegram && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.telegram}</span>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container mb-10">
        <label className="fs-6 fw-semibold mb-2">Источники трафика</label>

        <textarea
          rows={4}
          placeholder="Опишите источники трафика и воронку продаж"
          {...formik.getFieldProps("msg")}
          className={clsx(
            "form-control form-control-solid",
            {
              "is-invalid": formik.touched.msg && formik.errors.msg,
            },
            {
              "is-valid": formik.touched.msg && !formik.errors.msg,
            }
          )}
          name="msg"
        ></textarea>

        {formik.touched.msg && formik.errors.msg && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.msg}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group */}
      <div className="d-flex flex-stack mb-15">
        <div className="fw-semibold me-5">
          <label className="fs-6">
            Я подтверждаю владение источниками трафика
          </label>

          <div className="fs-7 text-gray-500">
            Готов предоставить потверждение владения
          </div>
        </div>
        <div className="d-flex align-items-center">
          <label
            className="form-check form-check-inline"
            htmlFor="kt_login_toc_agree"
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="fv-row mb-8"></div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={
            formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
          }
        >
          {!loading && <span className="indicator-label">Отправить</span>}
          {/* {!token && (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            )} */}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "GLOBAL.WAIT",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            {intl.formatMessage({
              id: "AUTH.GENERAL.BACK_BUTTON",
            })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
    // </GoogleReCaptchaProvider>
  );
}
