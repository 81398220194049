import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Avatar } from "./ProfileModels";
import {
  SimpleAgent,
  GetAllLinksForAgent,
  AgentRevShare,
  UserWallet,
  TronAddress,
  FreeWinBalance,
  FreeWinPostTransBalance,
  BodyWithdrawFromFreewinApiFreewinWithdrawPost,
  BodyWithdrawWalletApiTronUsdtWithdrawPost,
  TransactionHistory,
  GetTransaction,
  FreeWinURL,
  VerifyTransaction,
} from "../../../../../api/core-contract";
import { ReattachUserRefLinks } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const GET_ALL_AVATARS = `${API_URL}/settings/avatar/all`; // Показать доступные аватары

const CHOOSE_AVATAR = `${API_URL}/settings/avatar/choose_avatar`; // Выбрать аватар

const USER_DATA = `${API_URL}/user`;

const CHANGE_USER_DATA = `${API_URL}/user/settings`;

const REFERRAL_LINKS = `${API_URL}/management/agent/referral_links/all_links`;
const DELETE_REFERRAL_LINKS = `${API_URL}/management/agent/referral_links/blocked`;
const EDIT_DESCRIPTION_REFERRAL_LINK = `${API_URL}/management/agent/referral_links/description`;
const ACTIVATE_REFERRAL_LINK = `${API_URL}/management/agent/referral_links/activate`;
const DE_ACTIVATE_REFERRAL_LINK = `${API_URL}/management/agent/referral_links/deactivate`;
const CREATE_REFERRAL_LINK = `${API_URL}/management/agent/referral_links/set`;
const GET_ALL_REFERRAL_USERS = `${API_URL}/management/agent/referral_users/all_users`;
const GET_AGENT_DATA = `${API_URL}/management/agent/`;

// Wallet
const SET_DEPOSIT = `${API_URL}/freewin/deposit`;
const GET_FREEWIN_BALANCE = `${API_URL}/freewin/balance`;
const WITHDRAW_DEPOSIT = `${API_URL}/freewin/withdraw`;
const WITHDRAW_TRON = `${API_URL}/tron/usdt/withdraw`;
const GET_TRON_WALLET = `${API_URL}/tron/wallet`;
const GET_WALLET_TRANSACTION = `${API_URL}/wallet/transactions`;
const GET_WALLET = `${API_URL}/wallet/balance`;
const WALLET_VERIFICATION = `${API_URL}/wallet/verification`;
const RESET_TOTP_URL = `${API_URL}/totp/reset`;
const GET_TOTP_SECRET_CODE = `${API_URL}/totp/get`;

const REATTACH_USER_REF_LINKS = `${API_URL}/management/agent/referral_users/assign_user`;

export const useAllAvatar = () => {
  return useMutation({
    mutationFn: () => {
      return axios.get(GET_ALL_AVATARS);
    },
  });
};

export const useCreateReferralLink = (options: any = {}) => {
  return useMutation<string, any, any>({
    mutationFn: async (description) => {
      const response = await axios.post(CREATE_REFERRAL_LINK, null, {
        params: {
          description,
        },
      });

      return response.data;
    },
    ...options,
  });
};

export const useReferralLinks = (options: any = {}) => {
  return useQuery<GetAllLinksForAgent[]>(
    "referral_links",
    async () => {
      const { data } = await axios.get(REFERRAL_LINKS);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useActivateReferralLink = (args: any) => {
  return useMutation<any, any, GetAllLinksForAgent>({
    mutationFn: (link) => {
      return axios.put(`${ACTIVATE_REFERRAL_LINK}?link_id=${link.link_id}`);
    },
    ...args,
  });
};

export const useDeActivateReferralLink = (args: any) => {
  return useMutation<any, any, GetAllLinksForAgent>({
    mutationFn: (link) => {
      return axios.put(`${DE_ACTIVATE_REFERRAL_LINK}?link_id=${link.link_id}`);
    },
    ...args,
  });
};

export const useChooseAvatar = () => {
  return useMutation<any, any, Avatar>({
    mutationFn: (body) => {
      return axios.put(CHOOSE_AVATAR, body);
    },
  });
};

export const useDeleteReferralLink = (args: any) => {
  return useMutation<any, unknown, GetAllLinksForAgent["link_id"]>({
    mutationFn: (link_id) => {
      return axios.put(`${DELETE_REFERRAL_LINKS}?link_id=${link_id}`);
    },
    ...args,
  });
};

export const useEditDescriptionReferralLink = (args: any) => {
  return useMutation<any, unknown, GetAllLinksForAgent>({
    mutationFn: (link) => {
      return axios.put(
        `${EDIT_DESCRIPTION_REFERRAL_LINK}?link_id=${link.link_id}&description=${link.description}`
      );
    },
    ...args,
  });
};

export const useAllReferralUsersFreewin = (
  queryKey: string,
  params: any,
  options: any = {}
) => {
  return useQuery<any>(
    queryKey,
    async () => {
      const { data } = await axios.post(`${GET_ALL_REFERRAL_USERS}`, params);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useGetAgentData = () => {
  return useQuery<AgentRevShare>(
    "agent-data",
    async () => {
      const { data } = await axios.get(GET_AGENT_DATA);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUser = (options: any = {}) => {
  return useQuery<SimpleAgent>(
    "user-data",
    async () => {
      const { data } = await axios.get(USER_DATA);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useChangeUserTOTPStatus = () => {
  return useMutation<SimpleAgent>({
    mutationFn: (body) => {
      return axios.put(CHANGE_USER_DATA, body);
    },
  });
};

export const useGetTOTPSecretCode = () => {
  return useMutation(
    "totp-secret-code",
    async (sendVerifyData: VerifyTransaction) => {
      const { data: response } = await axios.post(
        GET_TOTP_SECRET_CODE,
        sendVerifyData
      );
      return response;
    }
  );
};
export const useTOTPResetUrl = () => {
  return useMutation({
    mutationFn: () => {
      return axios.put(RESET_TOTP_URL);
    },
  });
};

// Wallet
export const useWallet = (options: any = {}) => {
  return useQuery<UserWallet>(
    "wallet",
    async () => {
      const { data } = await axios.get(GET_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useTronWallet = (options: any = {}) => {
  return useQuery<TronAddress>(
    "tron-wallet",
    async () => {
      const { data } = await axios.get(GET_TRON_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
export const useFreewinBalance = (options: any = {}) => {
  return useQuery<FreeWinBalance>(
    "freewin-balance",
    async () => {
      const { data } = await axios.get(GET_FREEWIN_BALANCE);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};
export const useDeposit = () => {
  return useMutation<FreeWinPostTransBalance, any, number>(
    "deposit",
    async (amount: number) => {
      const { data: response } = await axios.post(SET_DEPOSIT, {
        amount,
      });
      return response.data;
    }
  );
};

export const useWithdrawDeposit = () => {
  return useMutation<
    FreeWinPostTransBalance,
    BodyWithdrawFromFreewinApiFreewinWithdrawPost,
    any
  >(
    "withdraw-deposit",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiFreewinWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT,
        sendWithdrawData
      );
      return response;
    }
  );
};

export const useWithdrawTron = () => {
  return useMutation<any, BodyWithdrawWalletApiTronUsdtWithdrawPost, any>(
    "withdraw-tron",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiFreewinWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_TRON,
        sendWithdrawData
      );
      return response;
    }
  );
};
export const useWalletTransactions = (options: any = {}) => {
  return useMutation<{ data: TransactionHistory[] }, any, GetTransaction>({
    mutationFn: async (data) => {
      const response = await axios.post(GET_WALLET_TRANSACTION, data);
      const totalPages = response.headers["x-total-pages"];
      return {
        data: response.data,
        totalPages,
      };
    },
  });
};

export const useWalletVerification = (options: any = {}) => {
  return useQuery<FreeWinURL>(
    "wallet_verification",
    async () => {
      const { data } = await axios.get(WALLET_VERIFICATION);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};


export const useReattachUserRefLinks = () => {
  return useMutation<any, any, ReattachUserRefLinks>(
    "reattach-user-ref",
    async ({ userId, linkId }) => {
      const { data: response } = await axios.put(
        `${REATTACH_USER_REF_LINKS}?userid=${userId}&linkid=${linkId}`
      );
      return response;
    }
  );
};