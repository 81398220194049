import { FC, createContext } from "react";
import { WithChildren } from "../../../_metronic/helpers";
import {
  useAllReferralUsersFreewin,
  useGetAgentData,
} from "./components/core/_requests";
import {
  calculateBonus,
  calculateProfit,
  calculateTotal,
  getCurrentMonthAndYear,
} from "../../utils";
import {
  CalcReferralData,
  ReferralUserFreewin,
} from "./components/core/ProfileModels";
import { useAllReferralUsersBetwallet } from "./components/reports-betwallet/core/_requests";
import {
  ReferralUserBetwallet,
  ReferralUserBetwalletWallets,
} from "./components/reports-betwallet/core/_models";
import { forEach } from "lodash";

interface Context {
  agentData?: string;
}
export const AgentContext = createContext<Context | any | null>(null);

export const AgentContextProvider: FC<WithChildren> = ({ children }) => {
  const { data: agentData, isLoading: isAgentDataLoading } = useGetAgentData();
  const { rev_share } = agentData || {};

  const date = getCurrentMonthAndYear();

  // ******
  // Удалить как антон починит
  // ******

  const BASEREQUEST = { ...date, page_size: 1000 };

  const { data: allReferralUsersDataFreewin } = useAllReferralUsersFreewin(
    "agent_profile_freewin",
    date
  );

  const { data: allReferralUsersDataBetwallet } = useAllReferralUsersBetwallet(
    "agent_profile_betwallet",
    BASEREQUEST
  );

  const calcReferralData = (): CalcReferralData[] => {
    const allReferalData: CalcReferralData[] = [];

    allReferralUsersDataFreewin?.forEach((item: ReferralUserFreewin) => {
      const profit = calculateProfit(item.winloss ?? 0);
      allReferalData.push({
        profit: profit,
        bonus: calculateBonus(profit, rev_share ?? 0),
        acc: `freewin - ${item.nickname}`,
      });
    });

    allReferralUsersDataBetwallet?.forEach((freewin: ReferralUserBetwallet) => {
      forEach(freewin.wallets, (betwallet: ReferralUserBetwalletWallets) => {
        if (freewin.freewin_code === betwallet.wallet) return;

        const profit = calculateProfit(betwallet.winloss ?? 0);
        allReferalData.push({
          profit: profit,
          bonus: calculateBonus(profit, rev_share ?? 0),
          acc: `betwallet - ${betwallet.wallet}`,
        });
      });
    });

    return allReferalData;
  };

  const agentTotalProfit = calculateTotal(calcReferralData(), "profit");
  const agentTotalBonus = calculateTotal(calcReferralData(), "bonus");

  return (
    <AgentContext.Provider
      value={{ agentTotalProfit, agentTotalBonus, isAgentDataLoading }}
    >
      {children}
    </AgentContext.Provider>
  );
};
