import { MonthAndYear } from "./modules/profile/components/core/ProfileModels";
import { round } from "lodash";

export const convertCentsToDollars = (cents: number | undefined): number => {
  if (!cents) return 0;
  return cents / 100; // Деление на 100, чтобы перевести центы в доллары
};

export const convertDollarsToCents = (dollars: number | undefined): number => {
  if (!dollars) return 0;
  return dollars * 100; // умножение на 100, чтобы перевести в центы
};

export const calculateProfit = (winloss: number): number => {
  return winloss * -1;
};
export const calculateBonus = (profit: number, rev_share: number): number => {
  return (profit * rev_share) / 100;
};

export const calculateTotal = (data: any[], key: string): number => {
  return data?.reduce((sum, item) => sum + ((item[key] as number) ?? 0), 0);
};

export const formatNumber = (num: number) => {
  return round(num, 2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export function getCurrentMonthAndYear() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  // return previous month data on 1st day of a new month
  if (day === 1) {
    return getNextOrPreviousMonth({ month, year }, false);
  }
  return { month, year };
}

export function getNextOrPreviousMonth(
  { month, year }: MonthAndYear,
  isNextMonth: boolean
) {
  const date = new Date(year, month - 1); // Subtracting 1 to get zero-based index for month

  if (isNextMonth) {
    date.setMonth(date.getMonth() + 1);
  } else {
    date.setMonth(date.getMonth() - 1);
  }

  const nextOrPreviousMonth = {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };

  return nextOrPreviousMonth;
}
