import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { MonthAndYear } from "../../core/ProfileModels";

const API_URL = process.env.REACT_APP_API_URL;

const GET_ALL_REFERRAL_USERS = `${API_URL}/management/agent/referral_users/all_users/v2`;

const GET_REFERRAL_USERS_CSV = `${API_URL}/management/agent/referral_users/all_users/report/csv`;


export const useAllReferralUsersBetwallet = (
  queryKey: string,
  params: any,
  options: any = {}
) => {
  return useQuery<any>(
    queryKey,
    async () => {
      const { data } = await axios.post(`${GET_ALL_REFERRAL_USERS}`, params);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useCSVReferralUsersBetwallet = (
) => {
  return useMutation<any, any, MonthAndYear>(
    'csv-betwallet',
    async (params) => {
      const { data } = await axios.post(`${GET_REFERRAL_USERS_CSV}`, params, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          // Добавьте любые другие необходимые заголовки (например, токен аутентификации)
        }
      })
      
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return data;
    }
  );
};


