import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "../../../../app/pages/main-page/components/NavLink";
import { FreeBetLogo } from "../../../../app/pages/main-page/components/FreeBetLogo";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";
import { KTSVG } from "../../../../_metronic/helpers";
import { Tooltip } from "bootstrap";

// Да, еще один вариант - использовать require для импорта файла из папки public:

// js

// Copy code
import termsPdf from "../../../../../src/_metronic/assets/files/terms.pdf";
import confidendalPolicyPdf from "../../../../../src/_metronic/assets/files/confidendal-policy.pdf";
import betRulesPdf from "../../../../../src/_metronic/assets/files/betRules.pdf";
import rulesInfoPdf from "../../../../../src/_metronic/assets/files/rulesInfo.pdf";

/* eslint-disable react/jsx-no-target-blank */
const Footer = () => {
  const intl = useIntl();

  const [tooltip, setTooltip] = useState<any>();
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const tooltip = new Tooltip(ref.current, {
        trigger: "manual",
        title: "Скопированно",
        offset: [0, 5],
      });
      setTooltip(tooltip);
    }
  }, []);

  const copyToClipBoard = async (address: string | undefined) => {
    try {
      await navigator.clipboard.writeText(address ?? "");
      if (tooltip) {
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
        }, 800);
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const emailFreewin = "help@freewin.gg";

  return (
    <div className="container app-container container-xxl fw-bold pt-5 pb-5">
      <div className="row align-items-baseline position-relative">
        <div className="col d-flex flex-column gap-4">
          <a
            href={termsPdf}
            target="_blank"
            className="fw-bold fs-6 text-white text-hover-freewin"
          >
            {intl.formatMessage({ id: "FOOTER.INFO" })}
          </a>

          <a
            href={rulesInfoPdf}
            target="_blank"
            className="fw-bold fs-6 text-white text-hover-freewin"
          >
            {intl.formatMessage({ id: "FOOTER.TERMS_AND_CONDITIONS" })}
          </a>

          <a
            href={betRulesPdf}
            target="_blank"
            className="fw-bold fs-6 text-white text-hover-freewin"
          >
            {intl.formatMessage({ id: "FOOTER.BETTING_RULES" })}
          </a>

          <a
            href={confidendalPolicyPdf}
            target="_blank"
            className="fw-bold fs-6 text-white text-hover-freewin"
          >
            {intl.formatMessage({ id: "FOOTER.PRIVACY_POLICY" })}
          </a>
          <NavLink title="FAQ" to="/faq" />
        </div>
        <div className="col d-flex flex-column gap-4">
          <NavLink
            title={
              <span className="fw-bold fs-6 ">
                {intl.formatMessage({ id: "FOOTER.SUPPORT" })}
              </span>
            }
            to="/dashboard"
          />
          <div className="fs-6 ml-4 d-flex  align-items-center">
            {emailFreewin}
            <button
              ref={ref}
              className="mx-2 p-0  btn  btn-light    "
              style={{ background: "transparent" }}
              onClick={() => copyToClipBoard(emailFreewin)}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen054.svg"
                className="m-0 svg-icon svg-icon-1 svg-icon-white "
              />
            </button>
          </div>
        </div>
        <div className="col d-flex flex-column gap-4">
          <NavLink
            title={
              <span className="fw-bold fs-6">
                {intl.formatMessage({ id: "FOOTER.SOCIAL" })}
              </span>
            }
            to="/dashboard"
          />
          <div>
            <NavLink
              title={
                <SVG
                  src={toAbsoluteUrl("/media/socialIcons/telegram.svg")}
                  width={20}
                  height="auto"
                  title="Telegram"
                  className="me-4"
                />
              }
              to="/tg"
            />
            <NavLink
              title={
                <SVG
                  src={toAbsoluteUrl("/media/socialIcons/discord.svg")}
                  width={20}
                  height="auto"
                  title="Telegram"
                />
              }
              to="/tg"
            />
          </div>
        </div>
        <div className="col d-none d-lg-flex flex-column text-end">
          <NavLink title={<FreeBetLogo />} to="/" />
          <div
            className="position-absolute bottom-0 end-0 bg-light rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: "40px", height: "40px" }}
          >
            18+
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
