import { NavLink } from "../../../../../app/pages/main-page/components/NavLink";
import { FreeBetLogo } from "../../../../../app/pages/main-page/components/FreeBetLogo";
import { toAbsoluteUrl } from "../../../../helpers";
import { HeaderUserMenu } from "../../../../partials";

export function MenuInner() {
  return (
    <>
      <div className="pt-3 container text-center align-items-center align-self-start align-self-lg-center">
        <div className="row align-items-center flex-lg-row fs-4 fs-lg-6">
          <div className="col text-start">
            <NavLink title={<FreeBetLogo />} to="/" />
          </div>
          <div className="col text-end">
            <div
              className="cursor-pointer symbol"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <img
                className="w-5 h-5"
                style={{ width: "40px", height: "40px" }}
                src={toAbsoluteUrl("/media/avatars/blank.png")}
                alt=""
              />
            </div>
            <HeaderUserMenu />
          </div>
        </div>
      </div>
    </>
  );
}
