/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { Login } from "./components/Login";
import { ConfirmPassword } from "./components/ConfirmPassword";
import { ForgotPassword } from "./components/ForgotPassword";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

export const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex  flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      // style={{
      //   background: "#001228",
      // }}
      style={{
        background: `url(${toAbsoluteUrl(
          "/media/auth/bg7-dark.jpg"
        )}) center center / cover no-repeat`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex justify-content-center justify-content-lg-end align-items-center p-20 w-100">
        {/* begin::Wrapper */}
        <div className="bg-body shadow-sm d-flex flex-column justify-content-center rounded-4 w-md-550px h-lg-100 me-lg-20 p-15 p-lg-15 ">
          <div className="py-15 py-lg-0 px-sm-15">
            <Outlet />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />

      {/* <Route path="registration">
        <Route index element={<Registration />} />
        <Route path="confirm" element={<ConfirmRegistration />} />
      </Route> */}

      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/*" element={<ConfirmPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
